@use '../../components/includes' as *;

.not_found {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include breakpoint($below-sm) {
    height: 600px;
  }
}

.not_found_background_image {
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(100vh - var(--navigation-with-border-height));

  img {
    width: 100%;
    object-fit: cover;
  }

  @include breakpoint($below-sm) {
    mask-image: linear-gradient(to bottom, black 0, black 65%, transparent 98%);
  }
}

.not_found_info_wrapper {
  display: flex;
  position: absolute;
  inset-block-start: 39%;
  inset-inline-start: 50%;
  flex-direction: column;
  align-items: center;
  width: 65%;
  transform: translate(-50%, -50%);
  text-align: center;

  h2 {
    @include h1;

    margin-block-end: $g24;
  }

  p {
    @include h4;

    margin-block-end: $g12;
  }

  @include breakpoint($below-sm) {
    h2 {
      font-size: $f16;
    }
  }

  @include breakpoint($sm) {
    inset-block-start: 50%;
    width: 75%;
  }

  @include breakpoint($md) {
    p {
      margin-block-end: $g16;
    }
  }

  @include breakpoint($lg) {
    h2 {
      margin-block-end: $g40;
    }

    p {
      margin-block-end: $g24;
    }
  }
}

.not_found_info_links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $g16;

  // Set the min height of the non-surprise-me buttons
  // to match the min height of a button with icon
  a {
    min-height: 33px;
  }
}

.not_found_show_logo {
  display: flex;
  position: absolute;
  inset-block-end: 5%;
  align-items: center;

  img {
    width: auto;
    max-height: 20px;
  }

  svg {
    height: 15px;
    margin-inline-start: $g16;
    fill: $white;
  }

  // Nature and Eons have some extra space at the end of
  // the logo, this adjusts the svg accordingly
  &:has(img[alt='Nature']) {
    svg {
      margin-inline-start: 0;
    }
  }

  &:has(img[alt='Eons']) {
    svg {
      margin-inline-start: -12px;
    }
  }

  &:has(img[alt='Sanditon']) {
    img {
      max-height: 40px;
    }

    svg {
      margin-inline-start: 12px;
    }
  }

  &:has(img[alt='PBS News Hour']) {
    img {
      max-height: 30px;
    }
  }

  @include breakpoint($sm) {
    inset-block-end: 5%;
    inset-inline-end: 3%;
  }
}
